
/* eslint-disable*/ 
import { marketPlace } from '@/axios'

export default {
  acceptMerchent (storeId, payload) {
    return marketPlace().patch(`admin/stores/${storeId}`, payload)
  },
  addMerchent (payload) {
    return marketPlace().post('admin/merchants', payload)
  },
  getCities () {
    return marketPlace().get('cities')
  },
  addTax (payload) {
    return marketPlace().post('admin/taxes', payload)
  },
  restoreMerchent (store_id){
    return marketPlace().patch(`admin/stores/restore/${store_id}`)
  }
}
